.text-updater-node {
  /* height: 200px; */
  width: 300px;
  border: 1px solid #eee;
  padding: 0px;
  border-radius: 5px;
  background: white;
  transition: box-shadow 0.3s;
}
.text-updater-node:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}
